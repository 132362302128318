import axios from 'axios'

export function WxRequest (config) {
  const Wxinstance = axios.create({
    baseURL: 'https://api.weixin.qq.com',
    timeout: 5000
  })

  Wxinstance.interceptors.request.use(config => {
    return config
  }, () => {
  })

  Wxinstance.interceptors.response.use(config => {
    return config.data
  }, () => {
  })

  return Wxinstance(config)
}
