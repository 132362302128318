<template>
  <div style="position: relative; "  >
    <img src="../assets/矩形 1.png" alt="" style="width: 100%; height: 100vh;" >
    <div style="position: absolute; top: 15%; width: 100%;" @click="fn">
      <img src="../assets/图层 25.png" alt="" width="80%" height="80%">
    </div>
    <!-- <audio id="bgMusic" src="../assets/4272600974.mp3" loop></audio>
    <div style="position: absolute; top: 5%; right: 10%;">
      <img v-if="music === 0" src="../assets/播放 (1).png" @click="handleCloseMusic" style="width: 0.8rem;">
      <img v-if="music === -1" src="../assets/播放.png" @click="playMusic" style="width: 0.8rem;">
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /srcs
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'
import { getWxOpenidAndToken, getWxUserMessage } from '../wx'

// import axios from 'axios'

export default {
  name: 'HomeView',
  components: {

  },
  data () {
    return {
      appid: 'wxf2a2dc8f6c432eb6',
      secret: '49daab312b70c8817f71a4d12f04aef6',
      code: '',
      money: 0,
      opendid: '',
      activity_id: 0,
      music: 0
    }
  },
  mounted () {
    this.getCode();
    // document.getElementById('bgMusic').play();
  },
  methods: {
    handleCloseMusic () {
      // 关闭背景音乐
      document.getElementById('bgMusic').pause();
      // 切换音乐的图标
      this.music = -1;
    },
    playMusic () {
      // 开启背景音乐
      document.getElementById('bgMusic').play();
      // 切换音乐的图标
      this.music = 0;
    },
    getCode () { // 非静默授权，第一次有弹框
      this.activity_id = this.getUrlCode().activity_id
      this.code = this.getUrlCode().code // 截取code
      console.log(11111111)
      console.log(this.code)
      console.log(this.activity_id)
      if (this.code !== '' && this.code != null && this.code !== undefined) { // 如果有code，则去请求 //encodeURIComponent
        this.getDataUrl()
      }
    },
    fn () {
      // 关闭背景音乐
      // document.getElementById('bgMusic').pause();
      if (this.code == null || this.code === '') { // 如果没有code，则去请求 //encodeURIComponent
        const local = 'https://vue.zhihuifu.com?activity_id=' + this.activity_id
        // const local = 'https://vue.zhihuifu.com'
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
        // eslint-disable-next-line no-undef
        getWxOpenidAndToken(this.appid, this.secret, this.code).then(function (res) {
          //   // eslint-disable-next-line eqeqeq
          console.log('这是res', res)
          if (res.access_token !== undefined) {
            getWxUserMessage(res.access_token, res.openid).then(function (resdata) {
              console.log('这是resdata', resdata)
              if (resdata.opendid) {
                this.opendid = resdata.opendid
                // this.fn()
              }
            })
          }
        })
      }
      console.log(this.opendid, this.code, this.activity_id)
    },
    getUrlCode () { // 截取url中的code方法
      const url = location.search
      this.winUrl = url
      // eslint-disable-next-line no-new-object
      const theRequest = new Object()
      // eslint-disable-next-line eqeqeq
      if (url.indexOf('?') != -1) {
        const str = url.substr(1)
        const strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
        }
      }
      console.log('这是theRequest', theRequest)
      return theRequest
    },
    getDataUrl () {
      axios.post('/a/red/sendred', { code: this.code, activity_id: this.activity_id }).then(res => {
        console.log(res.data.code)
        console.log(res.data.data.money)
        if (res.data.code === 0) {
          this.money = res.data.data.money
          this.$router.push({
            name: 'redPacket',
            query: {
              code: res.data.code,
              money: this.money
            }
          })
        } else {
          this.$router.push({
            name: 'redPacket',
            query: {
              code: res.data.code,
              msg: res.data.msg
            }
          })
        }
      })
    },
    getZGH () {
      window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwODMwMzI0Nw==#wechat_redirect'
    },
    dd () {
      this.$router.push({
        name: 'redPacket',
        query: {
          code: -1,
          msg: '太遗憾了,红包发完了'
        }
      })
    }
  }
}
</script>
<style scoped>
</style>
