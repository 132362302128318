// eslint-disable-next-line vue/multi-word-component-names
<template>
    <div>
          <div v-if="code === 0" style="position: relative; " >
            <audio id="clickSound" src="../assets/Noah Smith - Start Tone 39 Sound Effect.mp3"></audio>
            <img src="../assets/矩形 1.png" alt="" style="width: 100%; height: 100vh;">
            <div style=" width: 100%;position: absolute; top:10%; display: flex; justify-content: center;" >
              <img src="../assets/图层 23.png" alt="" width="80%" height="80%">
              <div style="position: absolute; top: 25%; width: 100%; display: flex; justify-content: center;">
                <p style=" color: #EB4638; font-size: 1.5rem; font-weight: bold;" >{{ money }}</p>
              </div>
            </div>
          </div>
          <div v-else-if="code === -1" style="position: relative; display: flex; justify-content: center; ">
            <audio id="clickSound" src="../assets/Superior Sound Effects - Bell Large Bell Bell Ring Ring Bell Ring Large Bell Bell C Sound Effects Sound Effect Sounds EFX Sfx FX Bells.mp3"></audio>
            <img src="../assets/矩形 1.png" alt="" style="width: 100%; height: 100vh;">
              <!-- <img src="../assets/组 10.png" alt="" width="80%" height="80%"> -->
              <!-- <div style="position: absolute; top:30%; left: 25%; width: 50%; height:5rem;  border: 0.1rem solid #fff;background-color: #fff; border-radius: 5%;  text-align: center;">
                <p style="font-weight: bold; margin-top: 1.5rem; margin-bottom: 1.5rem;" >{{ msg }}</p>
                <p style="color: #EB4638; font-size: 0.5rem;">即将进入商城获得更多好礼...</p>
              </div> -->
              <div style=" width: 100%;position: absolute; top:10%; display: flex; justify-content: center;" >
              <img src="../assets/¥.png" alt="" width="80%" height="80%">
              <div style="position: absolute; top: 30%; width: 100%; display: flex; justify-content: center;">
                <p style=" color: #EB4638; font-size: 0.5rem; font-weight: bold;" >{{msg}}</p>
              </div>
              <div style="position: absolute; top: 80%; width: 100%; display: flex; justify-content: center;">
                <p style="font-size: 0.5rem; color: white;" >即将跳转商城...</p>
              </div>
            </div>

            </div>
          </div>
</template>
<style>
  .images-css {
    width: 100%;
    height: 47remx;
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>
<script>
export default {
  data () {
    return {
      code: this.$route.query.code,
      money: this.$route.query.money,
      msg: this.$route.query.msg
    }
  },
  created () {
    setInterval(() => {
      window.location.href = 'https://abc.zhihuifu.com/addons/yun_shop/?menu=#/home?i=20'
    }, 3000)
  },
  mounted () {
    // document.getElementById('clickSound').play();
  }
}
</script>
<style>
</style>
